import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPage = () => (
  <Layout>
    <SEO title="blog" />
    <div className="home_wrapper">
      <div className="home_content">
        <div className="home_details">
          <div className="name">
            <h1>Coming Soon</h1>
            <h2>I am writing</h2>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default BlogPage
